import Vue from 'vue'
import CryptoJS from 'crypto-js'
export function Encrypt(word, keyStr) {
    keyStr = keyStr ? keyStr : 'hskiqyhfuolmzfde';
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    let hexStr = encrypted.toString();
    return hexStr
}
export function Decrypt(word, keyStr) {
        keyStr = keyStr ? keyStr : 'hskiqyhfuolmzfde';
        var key = CryptoJS.enc.Utf8.parse(keyStr); 
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}