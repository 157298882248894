import Vue from 'vue';
import Vuex from "vuex";

// 使用 Event Bus
const bus = new Vue();

export default bus;



