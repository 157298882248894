import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/home.vue'),
    meta: {
      title: "自述文件"
    },
    children: [{
        path: '/Welcome',
        name:'Welcome',
        component: () => import( /* webpackChunkName: "welcome" */ '../components/WelcomeHome/Welcome.vue'),
        meta: {
          title: '系统首页'
        }
      },
      {
        path: '/Announcement',
        name:'Announcement',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/Announcement.vue'),
        meta: {
          title: '公告管理'
        }
      },
      {
        path: '/Give',
        name:'Give',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/Give.vue'),
        meta: {
          title: '赠送设置'
        }
      },
      {
        path: '/GlobleDeploy',
        name:'GlobleDeploy',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/GlobleDeploy.vue'),
        meta: {
          title: '全局配置'
        }
      },
      {
        path: '/SalaList',
        name:'SalaList',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/SalaList.vue'),
        meta: {
          title: '大厅列表'
        }
      },
      {
        path: '/LoginConfig',
        name:'LoginConfig',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/LoginConfig.vue'),
        meta: {
          title: '登录列表'
        }
      },
      {
        path: '/GateConfig',
        name:'GateConfig',
        component: () => import( /* webpackChunkName: "Announcement" */ '../components/SystemSetup/GateConfig.vue'),
        meta: {
          title: '网关列表'
        }
      },
      {
        path: '/GameList',
        name:'GameList',
        component: () => import( /* webpackChunkName: "GameList" */ '../components/GameConfig/GameList.vue'),
        meta: {
          title: '游戏列表'
        }
      },
      {
        path: '/Gameclass',
        name:'Gameclass',
        component: () => import( /* webpackChunkName: "GameList" */ '../components/GameConfig/Gameclass.vue'),
        meta: {
          title: '游戏类目'
        }
      },
      {
        path: '/GameConfig',
        name:'GameConfig',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/GameConfig.vue'),
        meta: {
          title: '游戏配置'
        }
      },
      {
        path: '/GameConfiglog',
        name:'Gamelog',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../views/GameConfig/GameConfiglog.vue'),
        meta: {
          title: '游戏配置日志'
        }
      },
      {
        path: '/Suit',
        name:'Suit',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Suit.vue'),
        meta: {
          title: '渠道服管理'
        }
      },
      {
        path: '/SuitLog',
        name:'SuitLog',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/SuitLog.vue'),
        meta: {
          title: '渠道服管理日志'
        }
      },

      {
        path: '/Publicconfig',
        name:'Publicconfig',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Publicconfig.vue'),
        meta: {
          title: '公共库配置'
        }
      },
      {
        path: '/Configuration',
        name:'Configuration',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Configuration.vue'),
        meta: {
          title: '平台风控配置'
        }
      },
      {
        path: '/Analysis',
        name:'Analysis',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Analysis.vue'),
        meta: {
          title: '风控数据分析'
        }
      },
      {
        path: '/Analysislog',
        name:'Analysislog',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Analysislog.vue'),
        meta: {
          title: '风控日志'
        }
      },
      {
        path: '/Riskcontrol',
        name:'Riskcontrol',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Riskcontrol.vue'),
        meta: {
          title: '风控管理'
        }
      },
      {
        path: '/Riskcontrollog',
        name:'Riskcontrollog',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Riskcontrollog.vue'),
        meta: {
          title: '风控管理日志'
        }
      },
      {
        path: '/Manager',
        name:'Manager',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../components/GameConfig/Manager.vue'),
        meta: {
          title: '版本管理'
        }
      },
      {
        path: '/anRoomlist',
        name:'anRoomlist',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../views/GameConfig/analysis/anRoomlist.vue'),
        meta: {
          title: '风控房间详情'
        }
      },
      {
        path: '/RoomList',
        name:'RoomList',
        component: () => import( /* webpackChunkName: "LoginLog" */ '../views/GameConfig/RoomList.vue'),
        meta: {
          title: '房间列表'
        }
      },
      {
        path: '/ChangePwd',
        name:'ChangePwd',
        component: () => import( /* webpackChunkName: "GameConfig" */ '../views/Home/ChangePwd.vue'),
        meta: {
          title: '修改密码'
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/login.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
