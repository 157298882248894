import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI, {Message} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios';
import qs from 'qs'
import VueWechactTitle from 'vue-wechat-title';//动态路由插件
// import OnlyMessage from './assets/js/onlyMsgbox';//引入element ui message封装，避免同一消息反复弹出
Vue.use(VueWechactTitle);
import {
  Encrypt,
  Decrypt
} from "./assets/js/utils";
import bus from "./components/common/bus";
axios.defaults.baseURL = 'https://b.6c15dw9.net/';
Vue.use(ElementUI);

import vueJsonEditor from 'vue-json-editor'
Vue.use(vueJsonEditor);


// 为了实现Class的私有属性
const showMessage = Symbol('showMessage')
/**
 *  重写ElementUI的Message
 *  single默认值true，因为项目需求，默认只弹出一个，可以根据实际需要设置
 */
let messageInstance = null;
class DonMessage {
  success (options, single = true) {
    this[showMessage]('success', options, single)
  }
  warning (options, single = true) {
    this[showMessage]('warning', options, single)
  }
  info (options, single = true) {
    this[showMessage]('info', options, single)
  }
  error (options, single = true) {
    this[showMessage]('error', options, single)
  }
  [showMessage] (type, options, single) {
    if (messageInstance && single) {
      messageInstance.close()//先把原来的关闭
    }
    messageInstance = Message[type](options)//再创建新的消息
  }
}
Vue.prototype.$message = new DonMessage()


// Vue.prototype.$message = OnlyMessage;//实列化element ui message封装，避免同一消息反复弹出
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.bus = new Vue();

/* 请求拦截器 */
axios.interceptors.request.use(config => {
  let token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, error => {
  return Promise.reject(error)
});


Vue.prototype.$Http = function (options) {
  var defaults = {
    url: '',
    type: 'post',
    headers: {
      contentType: 'multipart/form-data',
    },
    params: {},
    successful: function () {},
    err: function () {},
  }
  options = Object.assign(defaults, options);

  axios.post(options.url, {
    "params": Encrypt(JSON.stringify(options.params))
  }).then(res => {
    var data = JSON.parse(Decrypt(res.data.data));
    if (res.status == 200) {
      options.successful(res, data);
    }
    if (!res.data.status) {}
  }, error => {
    if (error.response.data.code == 422) {
      localStorage.removeItem('token');
      this.$message.error('登录过期请重新登陆')
      this.$router.push('/login');
    }
  }).catch((res) => {
    options.err(res)
  })
}

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  next();
})




